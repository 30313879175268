<template>
  <div
    class="landing-container"
    style="padding: 3em 3em;"
  >
    <div style="display: block;">
      <v-row
        justify="center"
      >
        <v-col sm="12" md="6" lg="5" xl="4" class="mb-10">
          <p class="text-h2 azure-text mb-10">
            {{ $t('RegisterFormTitle') }}
          </p>
          <!-- <p class="text-h6">
            {{ $t('RegisterFormPara1') }}
          </p> -->
          <!-- <p class="text-h6">
            {{ $t('RegisterFormPara2') }}
          </p> -->
          <v-row>
            <v-col cols="auto">
              <div class="wite--text stepnum font-weight-bold">
                1
              </div>
            </v-col>
            <v-col cols="8">
              <div class="center-text-v">
                <span class="text-body-1">
                  Complete this form to request membership.
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <div class="wite--text stepnum font-weight-bold">
                2
              </div>
            </v-col>
            <v-col cols="8">
              <div class="center-text-v">
                <span class="text-body-1">
                  We will review your request.
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <div class="wite--text stepnum font-weight-bold">
                3
              </div>
            </v-col>
            <v-col cols="8">
              <div class="center-text-v">
                <span class="text-body-1">
                  If you are approved, you will recieve an invitation email with login instructions.
                </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="12" md="6" lg="5" xl="4">
          <v-card style="padding: 2em">
            <v-container fluid>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    filled
                    autocomplete="given-name"
                    :label="$t('RegisterFormFNameLabel')"
                    v-model="request.Fname"
                    :error-messages="fNameErrors"
                    @input="$v.request.Fname.$touch()"
                    @blur="$v.request.Fname.$touch()"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    filled
                    autocomplete="family-name"
                    :label="$t('RegisterFormLNameLabel')"
                    v-model="request.Lname"
                    :error-messages="lNameErrors"
                    @input="$v.request.Lname.$touch()"
                    @blur="$v.request.Lname.$touch()"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    filled
                    autocomplete="email"
                    :label="$t('RegisterFormEmailLabel')"
                    v-model="request.Email"
                    :error-messages="eMailErrors"
                    @input="$v.request.Email.$touch()"
                    @blur="$v.request.Email.$touch()"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    filled
                    autocomplete="organization"
                    :label="$t('RegisterFormCompanyLabel')"
                    v-model="request.Company"
                    :error-messages="companyErrors"
                    @input="$v.request.Company.$touch()"
                    @blur="$v.request.Company.$touch()"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    filled
                    autocomplete="organization-title"
                    :label="$t('RegisterFormTitleLabel')"
                    v-model="request.JobTitle"
                    :error-messages="titleErrors"
                    @input="$v.request.JobTitle.$touch()"
                    @blur="$v.request.JobTitle.$touch()"
                  />
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field
                    filled
                    :label="$t('RegisterFormContactLabel')"
                    v-model="request.EMNContact"
                    :error-messages="contactErrors"
                    @input="$v.request.EMNContact.$touch()"
                    @blur="$v.request.EMNContact.$touch()"
                  />
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="text-subtitle-1">
                    By continuing, you are agreeing to FastER's
                    <span
                      class="clickable"
                      @click="() => {this.showEULA = true}"
                    >
                      software license agreement.
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row
                justify="center"
              >
                <v-col cols="12">
                  <v-btn
                    color="var(--eastman-azure)"
                    class="white--text"
                    large
                    style="font-size: 16px;"
                    block
                    :loading="loading"
                    :disabled="disableSubmit"
                    @click="onRegisterSubmit"
                  >
                    {{ $t('RegisterFormSubmit') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="showEULA"
      :max-width="eulaDialogWidth"
    >
      <v-card class="pa-6">
        <v-card-title>
          <span class="text-h4">Software License Agreement</span>
          <v-spacer/>
          <v-btn
            text
            icon
            color="black"
            @click="() => {this.showEULA = false}"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="scroll-eula mb-8">
          <EulaText/>
        </div>
        <v-btn
          text
          color="var(--eastman-azure)"
          @click="onDownloadEula"
        >
          Download this agreement
          <v-icon>
            mdi-download
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <ErrorDialog
      :dialog="error"
      title="Error submitting registration"
      :message="errorMessage"
      @update:dialog="error = false"
    />
  </div>
</template>

<script>
import AccessAPI from '@/services/AccessServices.js'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators/'

function initialState() {
  return {
    request: {
      Fname: '',
      Lname: '',
      Email: '',
      Company: '',
      JobTitle: '',
      EMNContact: '',
    },
    showEULA: false,  
    loading: false,
    error: false,
    errorMessage: '',
  }
}

export default {
  Name: "Register",
  components: {
    EulaText: () => import('@/components/EulaText.vue'),
    ErrorDialog: () => import('@/components/ErrorDialog.vue'),
  },
  data: function () {
    return initialState()
  },
  validations: {
    request: {
      Fname: {
        required,
        maxLength: maxLength(128),
        minLength: minLength(2),
      },
      Lname: {
        required,
        maxLength: maxLength(128),
        minLength: minLength(2),
      },
      Email: {
        required,
        email,
        maxLength: maxLength(256),
        minLength: minLength(5),
      },
      Company: {
        required,
        maxLength: maxLength(128),
        minLength: minLength(5),
      },
      JobTitle: {
        required,
        maxLength: maxLength(128),
        minLength: minLength(5),
      },
      EMNContact: {
        maxLength: maxLength(256),
        minLength: minLength(5),
      },
    },
  },
  computed: {
    eulaDialogWidth: function () {
      var widthNum

      switch (this.$vuetify.breakpoint.name) {
        case 'xs': widthNum = 100; break;
        case 'sm': widthNum = 95; break;
        case 'md': widthNum = 90; break;
        case 'lg': widthNum = 60; break;
        default: widthNum = 50; break;
      }

      return `${widthNum}vw`
    },
    disableSubmit: function () {
      return this.$v.$invalid
    },
    fNameErrors () {
      const errors = []
      if (!this.$v.request.Fname.$dirty) return errors
      !this.$v.request.Fname.required && errors.push('First name is required')
      !this.$v.request.Fname.minLength && errors.push('Minimum 2 characters')
      !this.$v.request.Fname.maxLength && errors.push('Maximum 128 characters')
      return errors
    },
    lNameErrors () {
      const errors = []
      if (!this.$v.request.Lname.$dirty) return errors
      !this.$v.request.Lname.required && errors.push('Last name is required')
      !this.$v.request.Lname.minLength && errors.push('Minimum 2 characters')
      !this.$v.request.Lname.maxLength && errors.push('Maximum 128 characters')
      return errors
    },
    eMailErrors () {
      const errors = []
      if (!this.$v.request.Email.$dirty) return errors
      !this.$v.request.Email.required && errors.push('Email address is required')
      !this.$v.request.Email.email && errors.push('Please enter a valid email address')
      !this.$v.request.Email.minLength && errors.push('Minimum 5 characters')
      !this.$v.request.Email.maxLength && errors.push('Maximum 256 characters')
      return errors
    },
    companyErrors () {
      const errors = []
      if (!this.$v.request.Company.$dirty) return errors
      !this.$v.request.Company.required && errors.push('Company name is required')
      !this.$v.request.Company.minLength && errors.push('Minimum 5 characters')
      !this.$v.request.Company.maxLength && errors.push('Maximum 128 characters')
      return errors
    },
    titleErrors () {
      const errors = []
      if (!this.$v.request.JobTitle.$dirty) return errors
      !this.$v.request.JobTitle.required && errors.push('Job title name is required')
      !this.$v.request.JobTitle.minLength && errors.push('Minimum 5 characters')
      !this.$v.request.JobTitle.maxLength && errors.push('Maximum 128 characters')
      return errors
    },
    contactErrors () {
      const errors = []
      if (!this.$v.request.EMNContact.$dirty) return errors
      !this.$v.request.EMNContact.minLength && errors.push('Minimum 5 characters')
      !this.$v.request.EMNContact.maxLength && errors.push('Maximum 256 characters')
      return errors
    },
  },
  mounted: function () {
    if (this.$msal.isAuthenticated())
    {
      this.$router.push('/coatings/predict')
    }
  },
  methods: {
    onRegisterSubmit: async function () {
      //this.$router.push('/register/complete')
      this.loading = true

      // get captcha token
      try {
        await this.$recaptchaLoaded()
        var token = await this.$recaptcha('register')
        this.request.ReCaptchaToken = token
        try {
          await AccessAPI.sendAccessRequest(this.request)
          this.loading = false
          this.$router.push('/register/complete')
        } catch (error) {
          this.loading = false
          this.errorMessage = error.response?.data?.errorMessage || "Please try again in a moment. If the issue persists please contact us for additional support."
          this.error = true
        }
      } catch (error) {
        this.loading = false
        this.errorMessage = 'Captcha Error.'
        this.error = true
      }
      

      
    },
    onDownloadEula: function () {
      window.open('FastER_end_user_agreement.pdf')
    },
  },
}
</script>

<style>
.v-input__slot:before {
  border: none !important;
}

.stepnum {
  background-color: var(--eastman-blue);
  border-radius: 100%;
  color: #ffffff;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
}

.center-text-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.clickable {
  color: var(--eastman-azure);
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.scroll-eula {
  max-height: 60vh;
  overflow-y: scroll;
}

</style>